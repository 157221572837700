#rbcommons-container .config-forms-container,
#rbcommons-container .djblets-c-config-forms-page {
  width: 73em;
}
#rbcommons-container .config-forms-page-content,
#rbcommons-container .djblets-c-config-forms-page__content {
  width: 52em;
}
#rbcommons-container .config-forms-page-content .box {
  margin: 0 0 2em 0;
}
#rbcommons-container .config-forms-page-content .box .box-main .box-title {
  padding: 0.3em 0.3em 0.3em 0.5em;
}
#rbcommons-container .config-forms-page-content .box .box-main .djblets-l-config-forms-container.-is-recessed {
  /*
           * Since we have padding in the box, and the list won't actually be
           * flush, we need to restore the borders on the container.
           */
}
#rbcommons-container .config-forms-page-content .box .box-main .djblets-l-config-forms-container.-is-recessed.-is-bottom-flush {
  border-bottom: 1px #AAAAAA solid;
}
#rbcommons-container .config-forms-page-content .box .box-main .djblets-l-config-forms-container.-is-recessed.-is-top-flush {
  border-top: 1px #AAAAAA solid;
}
#rbcommons-container .config-forms-page-content .config-forms-link-list {
  list-style: none;
  margin: 1em 1em 1em 2em;
  padding: 0;
}
#rbcommons-container .config-forms-page-content .config-forms-link-list li {
  margin: 0.5em 0;
  padding: 0;
}
#rbcommons-container .config-forms-page-content .config-forms-link-list li a {
  color: #0000DD;
  font-weight: normal;
  text-decoration: none;
}
#rbcommons-container .config-forms-page-content .config-forms-link-list li a:hover {
  text-decoration: underline;
}
#rbcommons-container .config-forms-page-content .field {
  float: none;
}
#rbcommons-container .config-forms-side-nav {
  margin-top: 0;
}
#rbcommons-container .config-forms-side-nav .box-main {
  padding: 0;
}
#rbcommons-container .field .related-object-select li {
  margin: 0;
}
#rbcommons-container .field .related-object-select .description {
  margin: 0;
}
#rbcommons-container .field .selectize-control {
  display: inline-block;
  vertical-align: middle;
}
/*# sourceMappingURL=config-forms.css.map */