@font-face {
  font-family: 'Hit the Road';
  src: url(../fonts/hitroad.eot?) format('eot'), url(../fonts/hitroad.woff) format('woff'), url(../fonts/hitroad.ttf) format('truetype'), url(../fonts/hitroad.otf) format('opentype'), url(../fonts/hitroad.svg) format('svg');
}
@font-face {
  font-family: 'Halter';
  src: url(../fonts/halter.eot?) format('eot'), url(../fonts/halter.ttf) format('truetype');
}
body {
  margin: 0;
}
/* For browsers that don't support HTML5, we need to default the display. */
footer,
header,
main,
nav {
  display: block;
  margin: 0;
  padding: 0;
}
#rbcommons-container {
  position: relative;
}
#rbcommons-container.noscroll {
  overflow-y: none;
  height: 100%;
}
#rbcommons-container .btn.primary,
#rbcommons-container button.primary,
#rbcommons-container input[type=submit].primary {
  padding: 0.5em 1em;
}
#rbcommons-container .container label {
  width: 160px;
  display: inline-block;
  font-weight: normal;
}
#rbcommons-container .form-error {
  color: #e00;
}
#rbcommons-container .aside {
  line-height: 1.8em;
  margin: 0 2em 2em 2em;
  width: 100%;
}
#rbcommons-container .aside:last-child {
  margin-bottom: 0;
}
#rbcommons-container form {
  margin: 0 auto;
  width: 52em;
  padding: 1em;
}
#rbcommons-container form .fields-row {
  position: relative;
}
#rbcommons-container form .fields-row:last-child .field,
#rbcommons-container form .fields-row:last-of-type .field {
  margin-right: 0;
}
#rbcommons-container form .fields-row .btn,
#rbcommons-container form .fields-row input[type=submit] {
  line-height: 20px;
  margin: 0;
  padding: 4px 8px;
}
#rbcommons-container form .fields-row .field:last-of-type {
  margin-right: 0;
}
#rbcommons-container form .fields-row .field ul {
  list-style: none;
  margin: 1em 0;
  padding: 0;
}
#rbcommons-container form .fields-row .field ul li {
  margin: 0.5em 0;
}
#rbcommons-container form .fields-row .field ul li:last-child label {
  margin-bottom: 0;
}
#rbcommons-container form .fields-row .field ul li label {
  color: inherit;
}
#rbcommons-container form .hint {
  font-size: 85%;
  margin-top: 0.5em;
}
#rbcommons-container code {
  background-color: #f2f2f2;
  margin-left: 2em;
}
#rbcommons-container .thumb {
  float: right;
  margin-left: 1em;
}
.ui-autocomplete-results {
  font-family: Verdana, Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 13px;
}
form .spinner {
  width: 16px;
  height: 16px;
  margin-right: 1em;
  display: inline-block;
  background-image: url("../../rb/images/spinner.gif");
  background-repeat: no-repeat;
  vertical-align: middle;
}
@media screen and (max-width: 480px), screen and (max-device-width: 480px) and (orientation: landscape) {
  nav {
    display: block;
  }
}
#accountnav li a {
  font-weight: normal;
}
#accountnav li ul li {
  text-align: left !important;
}
#accountnav li .account-info-menu .account-info .account-contact-info {
  display: inline-block;
  margin: 1em;
  text-align: left;
}
#accountnav li .account-info-menu .account-info .account-contact-info .email {
  font-size: 90%;
  margin-top: 1em;
}
#accountnav li .account-info-menu .account-info .account-contact-info .full-name {
  font-size: 120%;
  font-weight: bold;
}
#accountnav li .teams-menu {
  min-width: 20em;
}
#accountnav li .teams-menu .team-info .team-customer-since {
  font-size: 90%;
  margin-top: 1em;
}
#accountnav li .teams-menu .team-info .team-name {
  font-size: 140%;
  font-weight: bold;
}
#accountnav li .teams-menu .switch-teams {
  font-size: 120%;
  font-weight: bold;
  padding-top: 2em;
}
@media screen and (max-width: 480px), screen and (max-device-width: 480px) and (orientation: landscape) {
  #accountnav {
    float: left;
    margin-left: 70px;
  }
  #accountnav > li {
    margin: 0;
  }
  #accountnav > li > a {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  #accountnav > li > ul {
    right: initial;
    left: 0;
  }
}
.permissions-offset {
  margin: 4em;
}
.secondary-nav {
  float: right;
  margin: 0 0 0 10px;
}
.secondary-nav a {
  color: #eeeeee;
}
.secondary-nav#navbar a {
  color: black;
}
#headerbar #rbinfo #title {
  vertical-align: middle;
  font-family: "Hit the Road";
  font-size: 16px;
  font-weight: normal;
  line-height: 1.2;
  margin-left: -1px;
  /* Helps align the text with the navigation. */
}
@media screen and (max-width: 480px), screen and (max-device-width: 480px) and (orientation: landscape) {
  #headerbar #rbinfo {
    float: none;
    position: relative;
  }
  #headerbar #rbinfo #title {
    margin-left: 6px;
    line-height: normal;
  }
}
/*
 * Move the bubble to the center of the page, so it doesn't interfere with
 * Intercom. We'll also style this to be more appropriate for the center.
 * We set a higher precedence level, since this stylesheet comes after the
 * matching one in Review Board.
 */
body #updates-bubble {
  border-bottom: 0;
  border-right: 1px #888866 solid;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px 10px 0 0;
  bottom: 0;
  left: 50%;
  right: auto;
  padding: 1em;
  transform: translateX(-50%);
}
#intercom-container .intercom-launcher {
  /* Add some room above the custom Intercom button. */
  bottom: 45px !important;
}
#rbc-intercom-button {
  background: #3A81C2;
  border: 1px #1E72C7 solid;
  border-bottom: 0;
  border-right: 0;
  border-radius: 12px 0 0 0;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  color: white;
  cursor: pointer;
  font-size: 115%;
  display: inline-block;
  padding: 0.5em 0.8em 0.5em 0.8em;
  position: fixed;
  bottom: -0.3em;
  right: -0.3em;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  transition: transform 0.1s ease-in-out;
  z-index: 90;
  background-color: #3A81C2;
  background-repeat: repeat-x;
  background-image: linear-gradient(to bottom, #4498E4, #3A81C2);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4498E4', endColorstr='#3A81C2', GradientType=0);
}
#rbc-intercom-button:after {
  background-repeat: no-repeat;
  content: "";
  display: inline-block;
  width: 22px;
  height: 19px;
  margin-left: 0.6em;
  vertical-align: middle;
  /* Set up the default image and size. */
  background-image: url('../images/intercom-comment-icon.png');
  background-size: 18px 15px;
  /*
   * If there are 2 or more ratio levels, loop through and create media
   * selectors for each.
   */
  /*
   * If SVG is enabled, create a media selector for it based on the max
   * ratio.
   */
}
@media (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  #rbc-intercom-button:after {
    background-image: url(../images/intercom-comment-icon@2x.png);
    background-size: 18px 15px;
  }
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  #rbc-intercom-button:after {
    background-image: url(../images/intercom-comment-icon@2x.png);
    background-size: 18px 15px;
  }
}
#rbc-intercom-button:hover {
  /*
     * We translate instead of messing with bottom or padding in order to
     * work around some odd visual glitches on Chrome where the icon (due to
     * being a background) fails to animate in sync with the rest of the
     * button.
     */
  transform: translateY(-0.3em);
}
/*# sourceMappingURL=common.css.map */