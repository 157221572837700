#rbcommons-container #auth_container #two_factor_box,
#rbcommons-container #auth_container #two_factor_setup {
  margin-left: auto;
  margin-right: auto;
  max-width: 35em;
}
#rbcommons-container #auth_container #two_factor_box form,
#rbcommons-container #auth_container #two_factor_setup form {
  width: auto;
}
#rbcommons-container #auth_container #two_factor_box p,
#rbcommons-container #auth_container #two_factor_setup p {
  margin-top: 0;
}
#rbcommons-container #auth_container #two_factor_box table th,
#rbcommons-container #auth_container #two_factor_setup table th {
  padding-right: 0.5em;
  vertical-align: middle;
}
#rbcommons-container #auth_container #two_factor_box #two_factor_method ul,
#rbcommons-container #auth_container #two_factor_setup #two_factor_method ul {
  list-style: none;
  margin: 2em;
  padding: 0;
}
#rbcommons-container #auth_container #two_factor_box #two_factor_method ul li,
#rbcommons-container #auth_container #two_factor_setup #two_factor_method ul li {
  margin: 1em 0 0 0;
}
#rbcommons-container #auth_container #two_factor_box #two_factor_phone_token_field,
#rbcommons-container #auth_container #two_factor_setup #two_factor_phone_token_field {
  margin: 2em auto 1em auto;
  width: 20em;
}
#rbcommons-container #auth_container #two_factor_box #two_factor_phone_token_field input,
#rbcommons-container #auth_container #two_factor_setup #two_factor_phone_token_field input {
  width: 15em;
}
#rbcommons-container #auth_container #two_factor_box #two_factor_qr_token_field input,
#rbcommons-container #auth_container #two_factor_setup #two_factor_qr_token_field input {
  width: 15em;
}
@media screen and (max-width: 480px), screen and (max-device-width: 480px) and (orientation: landscape) {
  #rbcommons-container #auth_container #two_factor_box #two_factor_qr_token_field,
  #rbcommons-container #auth_container #two_factor_setup #two_factor_qr_token_field {
    margin-left: 0;
    margin-right: 0;
    text-align: center;
  }
  #rbcommons-container #auth_container #two_factor_box #two_factor_qr_token_field input,
  #rbcommons-container #auth_container #two_factor_setup #two_factor_qr_token_field input {
    width: 10em;
  }
}
#rbcommons-container #auth_container #two_factor_box .auth-button-container,
#rbcommons-container #auth_container #two_factor_setup .auth-button-container {
  margin-top: 3em;
  padding-right: 12em;
  text-align: center;
  width: auto;
}
#rbcommons-container #auth_container #two_factor_box .auth-button-container button,
#rbcommons-container #auth_container #two_factor_setup .auth-button-container button {
  width: 45%;
}
@media screen and (max-width: 480px), screen and (max-device-width: 480px) and (orientation: landscape) {
  #rbcommons-container #auth_container #two_factor_box .auth-button-container,
  #rbcommons-container #auth_container #two_factor_setup .auth-button-container {
    margin-top: 2em;
    padding-right: 0;
  }
}
#rbcommons-container #auth_container #two_factor_box .fields-row,
#rbcommons-container #auth_container #two_factor_setup .fields-row {
  margin: 2em 0 1em 2em;
}
#rbcommons-container #auth_container #two_factor_box .fields-row .errorlist,
#rbcommons-container #auth_container #two_factor_setup .fields-row .errorlist {
  display: block;
}
#rbcommons-container #auth_container #two_factor_box .fields-row input,
#rbcommons-container #auth_container #two_factor_setup .fields-row input {
  width: 15em;
}
#rbcommons-container #auth_container #two_factor_box .fields-row label,
#rbcommons-container #auth_container #two_factor_setup .fields-row label {
  display: inline;
  margin-right: 1em;
}
#rbcommons-container #auth_container #two_factor_box .qr-code-container,
#rbcommons-container #auth_container #two_factor_setup .qr-code-container {
  float: right;
  margin: 2em 2em 0 2em;
}
#rbcommons-container #auth_container #two_factor_box .qr-code-container .qr-code,
#rbcommons-container #auth_container #two_factor_setup .qr-code-container .qr-code {
  background: white;
  border: 1px #a6a6a6 solid;
}
@media screen and (max-width: 480px), screen and (max-device-width: 480px) and (orientation: landscape) {
  #rbcommons-container #auth_container #two_factor_box .qr-code-container,
  #rbcommons-container #auth_container #two_factor_setup .qr-code-container {
    float: none;
    margin: 0 0 2em 0;
    text-align: center;
  }
}
#rbcommons-container #auth_container #two_factor_box .two-factor-cancel,
#rbcommons-container #auth_container #two_factor_setup .two-factor-cancel {
  color: darkred;
  display: block;
  font-size: 95%;
  margin-top: 3em;
  text-align: center;
}
#rbcommons-container #auth_container #two_factor_box .two-factor-cancel .fa-close,
#rbcommons-container #auth_container #two_factor_setup .two-factor-cancel .fa-close {
  color: #EE0000;
  font-size: 110%;
}
@media screen and (max-width: 480px), screen and (max-device-width: 480px) and (orientation: landscape) {
  #rbcommons-container #auth_container #two_factor_box .two-factor-cancel,
  #rbcommons-container #auth_container #two_factor_setup .two-factor-cancel {
    margin-top: 2em;
  }
}
#rbcommons-container #auth_container #login_token_box .two-factor-login-backups {
  margin-top: 2em;
}
#rbcommons-container #auth_container #login_token_box .two-factor-login-backups div {
  margin-top: 1em;
  text-align: center;
}
#rbcommons-container #auth_container #login_token_box .two-factor-login-backups div button {
  background: 0;
  border: 0;
  color: blue;
  display: inline;
  margin: 0;
  padding: 0;
}
#rbcommons-container #auth_container #login_token_box .two-factor-login-backups div p {
  margin: 0 0 0.5em 0;
}
#rbcommons-container #auth_container #two_factor_backup_tokens {
  margin-left: 0;
}
#rbcommons-container #auth_container #two_factor_backup_tokens_list {
  border: 1px #CCC solid;
  background: #FEFEFE;
  margin: 0;
  padding: 2em;
  font-family: "Monaco", Courier New, monospace;
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
}
#rbcommons-container #auth_container #two_factor_disable_confirm p {
  margin-left: 2em;
  margin-right: 2em;
  text-align: left;
}
#rbcommons-container #auth_container #two_factor_setup {
  max-width: 50em;
}
#rbcommons-container #auth_container #two_factor_phone_number_row.fields-row {
  margin: 2em 0;
  text-align: center;
}
#rbcommons-container #auth_container #two_factor_phone_number_row.fields-row .field {
  width: 100%;
}
#rbcommons-container #auth_container #two_factor_phone_number_row.fields-row .field input {
  width: auto;
}
#rbcommons-container #auth_container #two_factor_phone_number_row.fields-row .field label {
  margin-left: 0;
}
#rbcommons-container #auth_container .two-factor-disabled {
  color: #990000;
}
#rbcommons-container #auth_container .two-factor-enabled {
  color: #006600;
}
/*# sourceMappingURL=two-factor.css.map */